/**
 * Home page content
 */

//---------------------------------------
import { Container, Row, Col } from "reactstrap";
import ReactFlagsSelect from "react-flags-select";
import { Form, Button, Modal } from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";

// https://chatscope.io/storybook/react/?path=/docs/documentation-introduction--docs
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
    MessageSeparator,
} from "@chatscope/chat-ui-kit-react";

import {
    getLanguageCode,
    getLangByCountryCode,
    getAIReplyHeader,
    postRequest,
    isEmptyNullUndefined,
    jsonKeyExists,
    getCountryCodes,
    getCountryLanguageCustomLabels,
    getLoginState,
    initialMessages,
    getInitalImageContent,
    formateReturnTextToMessage,
    updateLocalCredits,
    getLocalCredits,
    getLocalEmail,
    setLocalMessages,
    getLastNMessages,
    modifyImgTags,
    base64toFile,
    extractSrcValues,
    formateTextToMessage,
    getFinalImageQuestion,
    getDataForHomePage,
    extractTextInputStr,
    removeSpacesAndHtmlSpaces,
} from "../../lib";

import { actionFeedbacks, warnings, maxImagesUpload, defaultLanguage, textConsumedCredits, imageConsumedCredits } from "../../lib/config";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import "./index.css";
import "../../App.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

const {
    introduceImageQuestion,
    translationImageQuestion,
    otherImageQuestion,
    translationTextQuestion,
    translateSysContent,
} = require("../../lib/gpt/config");

const maxFilesUpload = maxImagesUpload;
const defaultLang = defaultLanguage;

//---------------------------------------
function AIAssistant(props) {
    const [selectedCountryCode, setSelectedCountryCode] = useState("GB");
    const [messages, setMessages] = useState(initialMessages());
    // ---------------------------------------------
    const [isTyping, setIsTyping] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [images, setImages] = useState([]);
    const [pastedImages, setPastedImages] = useState([]);
    const [pastedImagesExtraInfo, setPastedImagesExtraInfo] = useState([]);
    const [sendButtonDisabled, setSendButtonDisabled] = useState(true);

    const [isDragging, setIsDragging] = useState(false);

    const [maxFilesWarn, setMaxFilesWarn] = useState(false);
    const [noLoginWarn, setNoLoginWarn] = useState(false);

    const [explainMoreValue, setExplainMoreValue] = useState("");
    const [hideExplainMore, setHideExplainMore] = useState(true);

    const [preDefindQuestionValue, setPreDefindQuestionValue] = useState(introduceImageQuestion);
    const fileInputRef = useRef(null);

    // Set for Snackbar - start
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarAutoHideDuration, setSnackbarAutoHideDuration] = useState(5000);
    const [snackbarAlertText, setSnackbarAlertText] = useState("Invalid email or password");
    const [alertSeverity, setAlertSeverity] = useState("warning");
    const [alertVariant, setAlertVariant] = useState("filled");
    const [inputValue, setInputValue] = useState("");
    const snackbarOnClose = () => {
        setSnackbarOpen(false);
    };

    const showWarningSnackbar = (warnText) => {
        setSnackbarOpen(true);
        setSnackbarAlertText(warnText);
        setAlertSeverity("warning");
    };
    // Set for Snackbar - end

    const handleMaxFilesWarnOpen = () => {
        showWarningSnackbar(warnings.maxImagesWarning);
    };

    function selectFiles(event) {
        event.preventDefault();
        fileInputRef.current.click();
    }

    function onFilesSelect(event) {
        event.preventDefault();
        const files = event.target.files;
        const curAllowedFilesNum = maxFilesUpload - images.length;
        if (!aboveMaxUploadCheck(files) && files.length !== 0) {
            for (let i = 0; i < (curAllowedFilesNum < files.length ? curAllowedFilesNum : files.length); i++) {
                if (files[i].type.split("/")[0] !== "image") continue;
                if (!images.some((e) => e.name === files[i].name)) {
                    // Currently, only support images
                    setImages((prevImages) => [
                        ...prevImages,
                        {
                            file: files[i],
                            name: files[i].name,
                            url: URL.createObjectURL(files[i]),
                        },
                    ]);
                }
            }
        }
    }

    const handleAttach = () => {
        if (!getLoginState()) {
            showWarningSnackbar(warnings.loginRegisterFirstWarning);
        } else {
            setShowModal(true);
        }
    };

    function deleteImage(index) {
        console.log(index);
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    }

    function onDragOver(event) {
        event.preventDefault();
        setIsDragging(true);
        event.dataTransfer.dropEffect = "copy";
    }
    function onDragLeave(event) {
        event.preventDefault();
        setIsDragging(false);
    }
    function onDrop(event) {
        event.preventDefault();
        setIsDragging(false);
        const files = event.dataTransfer.files;
        if (aboveMaxUploadCheck(files)) return;
        const currentMaxFiles = maxFilesUpload - images.length;
        for (let i = 0; i < (currentMaxFiles > 0 ? currentMaxFiles : 0); i++) {
            if (files[i].type.split("/")[0] !== "image") continue;
            if (!images.some((e) => e.name === files[i].name)) {
                setImages((prevImages) => [
                    ...prevImages,
                    {
                        file: files[i],
                        name: files[i].name,
                        url: URL.createObjectURL(files[i]),
                    },
                ]);
            }
        }
    }

    function aboveMaxUploadCheck(files) {
        const filesNum = files.length;
        if (images.length + filesNum > maxFilesUpload) {
            handleMaxFilesWarnOpen();
        }
        if (images.length >= maxFilesUpload) {
            return true;
        }
        return false;
    }

    async function uploadPastedImages() {
        const newOutgoMessages = [];
        const errorReplies = [
            {
                file_type: "text",
                message: actionFeedbacks.chatServerError,
            },
        ];
        try {
            // console.log("In uploadPastedImages...");
            // Check enought credits
            const currentCredits = getLocalCredits();
            if (currentCredits < imageConsumedCredits * pastedImages.length) {
                showWarningSnackbar(warnings.noCredits);
                return;
            }

            pastedImages.forEach((image, index) => {
                newOutgoMessages.push(getInitalImageContent(image, index));
            });
            if (removeSpacesAndHtmlSpaces(pastedImagesExtraInfo).length > 0) {
                newOutgoMessages.push(formateTextToMessage(pastedImagesExtraInfo));
            }

            const newMessages = [...messages, ...newOutgoMessages];
            setMessages(newMessages);
            setLocalMessages(newMessages);

            setPastedImages([]);
            setIsTyping(true);
            setInputValue("");
            const hasImage = true;
            const selectedLanguage = getLangByCountryCode(selectedCountryCode);
            const selectedLangCode = getLanguageCode(selectedCountryCode);
            const finalQuestion = getFinalImageQuestion(pastedImagesExtraInfo, "");

            let gptReplies = await getDataForHomePage(
                newMessages,
                pastedImages,
                hasImage,
                finalQuestion,
                selectedLangCode,
                selectedLanguage,
                radioValue
            );

            // // console.log("selectedLangCode: ", selectedLangCode);
            // if (selectedLanguage !== defaultLang) {
            //     // console.log("Need to translate");
            //     // Formate replies
            //     // let formatedRepliesMessages = [formateTextToMessage(`${translationTextQuestion} ${selectedLanguage}.`)];
            //     let formatedRepliesMessages = [formateTextToMessage(`${translateSysContent} ${selectedLanguage}.`)];
            //     gptReplies.forEach((reply) => {
            //         formatedRepliesMessages.push(formateTextToMessage(reply.message));
            //     });
            //     const res = await postRequest("/home-page-data", {
            //         method: "POST",
            //         headers: { "Content-Type": "application/json" },
            //         body: JSON.stringify({
            //             messages: getLastNMessages(formatedRepliesMessages),
            //             prefer_language: selectedLanguage,
            //             language_code: selectedLangCode,
            //         }),
            //     });
            //     if (res.data.code === 200) {
            //         gptReplies = [
            //             {
            //                 file_type: "text",
            //                 message: res.data.data.chat_reply,
            //             },
            //         ];
            //     } else {
            //         showWarningSnackbar(actionFeedbacks.serverError);
            //         gptReplies = errorReplies;
            //     }
            // } else {
            //     // console.log("No Need to translate");
            // }
            handleGPTReply(newOutgoMessages, gptReplies);
            setExplainMoreValue("");
        } catch (error) {
            handleGPTReply(newOutgoMessages, errorReplies);
            setExplainMoreValue("");
        }
    }

    async function uploadImages() {
        let newOutgoMessages = [];
        const errorReplies = [
            {
                file_type: "text",
                message: actionFeedbacks.chatServerError,
            },
        ];
        try {
            // Check enought credits
            const currentCredits = getLocalCredits();
            if (currentCredits < imageConsumedCredits * images.length) {
                showWarningSnackbar(warnings.noCredits);
                return;
            }

            images.forEach((image, index) => {
                newOutgoMessages.push(getInitalImageContent(image, index));
            });

            const newMessages = [...messages, ...newOutgoMessages];
            setMessages(newMessages);
            setLocalMessages(newMessages);

            setShowModal(false);
            setImages([]);

            setIsTyping(true);
            const hasImage = true;
            const selectedLanguage = getLangByCountryCode(selectedCountryCode);
            const selectedLangCode = getLanguageCode(selectedCountryCode);
            const finalQuestion = getFinalImageQuestion(explainMoreValue, radioValue);
            let gptReplies = await getDataForHomePage(
                newMessages,
                images,
                hasImage,
                finalQuestion,
                selectedLangCode,
                selectedLanguage,
                radioValue
            );

            // // console.log("selectedLangCode: ", selectedLangCode);
            // if (selectedLanguage !== defaultLang) {
            //     // console.log("Need to translate");
            //     // Formate replies
            //     // let formatedRepliesMessages = [formateTextToMessage(`${translationTextQuestion} ${selectedLanguage}.`)];
            //     let formatedRepliesMessages = [formateTextToMessage(`${translateSysContent} ${selectedLanguage}.`)];
            //     gptReplies.forEach((reply) => {
            //         formatedRepliesMessages.push(formateTextToMessage(reply.message));
            //     });
            //     const res = await postRequest("/home-page-data", {
            //         method: "POST",
            //         headers: { "Content-Type": "application/json" },
            //         body: JSON.stringify({
            //             messages: getLastNMessages(formatedRepliesMessages),
            //             prefer_language: selectedLanguage,
            //             language_code: selectedLangCode,
            //         }),
            //     });
            //     if (res.data.code === 200) {
            //         gptReplies = [
            //             {
            //                 file_type: "text",
            //                 message: res.data.data.chat_reply,
            //             },
            //         ];
            //     } else {
            //         showWarningSnackbar(actionFeedbacks.serverError);
            //         gptReplies = errorReplies;
            //     }
            // } else {
            //     // console.log("No Need to translate");
            // }
            handleGPTReply(newOutgoMessages, gptReplies);
            setExplainMoreValue("");
        } catch (error) {
            handleGPTReply(newOutgoMessages, errorReplies);
            setExplainMoreValue("");
        }
    }

    const handleInputChange = (inputContent) => {
        let srcValues = extractSrcValues(inputContent);
        const extractInfo = extractTextInputStr(inputContent);
        if (srcValues.length > maxFilesUpload) {
            handleMaxFilesWarnOpen();
            srcValues = srcValues.slice(0, maxFilesUpload);
        }
        const modifiedContent = modifyImgTags(inputContent);
        const pastedFiles = [];
        srcValues.forEach((srcValue) => {
            const tempFile = base64toFile(srcValue);
            pastedFiles.push({
                file: tempFile,
                name: tempFile.name,
                url: URL.createObjectURL(tempFile),
            });
        });
        setInputValue(`${modifiedContent}`);
        if (modifiedContent.trim().length) {
            setSendButtonDisabled(false);
        } else {
            setSendButtonDisabled(true);
        }
        if (pastedFiles.length > 0) {
            setPastedImages(pastedFiles);
            setPastedImagesExtraInfo(extractInfo);
        }
    };

    // const handlePaste = (event) => {
    //     // Access the clipboard data from the event
    //     const clipboardData = event.clipboardData || window.clipboardData;
    //     if (!clipboardData) return;

    //     // Check if the clipboard contains image data
    //     if (clipboardData.items) {
    //         for (const item of clipboardData.items) {
    //             if (item.type.startsWith("image")) {
    //                 const file = item.getAsFile(); // Get the pasted image as a File object
    //                 if (file) {
    //                     console.log("file:", file);
    //                     // Display the pasted image (optional)
    //                     const reader = new FileReader();
    //                     reader.onload = (e) => {
    //                         const imageUri = e.target.result;
    //                         console.log("Pasted image URI:", imageUri);
    //                     };
    //                     reader.readAsDataURL(file); // Read image as data URL
    //                 }
    //             }
    //         }
    //     }
    // };
    const handleSend = async (message) => {
        // console.log("In handleSend...");
        setSendButtonDisabled(true);
        if (!getLoginState()) {
            showWarningSnackbar(warnings.getFreeCreditsWarning);
            return;
        }
        const currentCredits = getLocalCredits();
        if (currentCredits < textConsumedCredits) {
            showWarningSnackbar(warnings.noCredits);
            return;
        }
        if (pastedImages.length > 0) {
            setIsTyping(true);
            setInputValue("");
            await uploadPastedImages();
            setIsTyping(false);
            setPastedImagesExtraInfo("");
            return;
        }

        const outgoMessage = {
            props: {
                model: {
                    role: "user",
                    messageType: "Text",
                    message,
                    sender: "user",
                    direction: "outgoing",
                    chat_session: true,
                },
            },
        };
        const newMessages = [...messages, outgoMessage];
        setMessages(newMessages);
        setLocalMessages(newMessages);
        setIsTyping(true);
        setInputValue("");
        const selectedLanguage = getLangByCountryCode(selectedCountryCode);
        const selectedLangCode = getLanguageCode(selectedCountryCode);
        const gptReplies = await getDataForHomePage(newMessages, undefined, undefined, undefined, selectedLangCode, selectedLanguage);
        handleGPTReply([outgoMessage], gptReplies);
    };

    function handleGPTReply(incomingMessages, gptReplies) {
        if (gptReplies.length === 0) return;
        let replyMessages = [];
        gptReplies.forEach((gptReply) => {
            if (!isEmptyNullUndefined(gptReply)) {
                const replyMessage = {
                    props: {
                        model: {
                            role: "assistant",
                            messageType: "Text",
                            message:
                                gptReply.file_type === "image"
                                    ? `${getAIReplyHeader(gptReply.file_index, gptReplies.length)}${gptReply.message}`
                                    : gptReply.message,
                            sender: "GHAI",
                            direction: "incoming",
                            chat_session: true,
                        },
                    },
                };
                replyMessages.push(replyMessage);
            }
        });

        const newMessages = [...messages, ...incomingMessages, ...replyMessages];
        setMessages(newMessages);
        setLocalMessages(newMessages);

        setIsTyping(false);
    }

    function handleModalClose() {
        setShowModal(false);
    }

    const [radioValue, setRadioValue] = useState("introduce");
    const [upSendButtonDisabled, setUpSendButtonDisabled] = useState(true);
    const [upSendButtonClass, setUpSendButtonClass] = useState("secondary");

    useEffect(() => {
        if (images.length > 0) {
            setUpSendButtonDisabled(false);
            setUpSendButtonClass("success");
        } else {
            setUpSendButtonDisabled(true);
            setUpSendButtonClass("Secondary");
        }
    }, [
        hideExplainMore,
        showModal,
        maxFilesWarn,
        noLoginWarn,
        images,
        selectedCountryCode,
        radioValue,
        explainMoreValue,
        preDefindQuestionValue,
    ]);

    const radioChange = (event) => {
        const radioValue = event.target.value;
        setRadioValue(radioValue);
        if (radioValue === "introduce") {
            setPreDefindQuestionValue(`${introduceImageQuestion}`);
            // setHideExplainMore(false);
            setHideExplainMore(true);
            // setExplainMoreValue("");
        } else if (radioValue === "translate") {
            setPreDefindQuestionValue(`${translationImageQuestion}`);
            setHideExplainMore(true);
            // setExplainMoreValue("");
        } else if (radioValue === "other") {
            setPreDefindQuestionValue(`${otherImageQuestion}`);
            setHideExplainMore(false);
        }
    };

    // const explainMoreInputChange = (event) => {
    //     const inputMore = event.target.value.trim();
    //     if (!isEmptyNullUndefined(inputMore)) {
    //         // console.log("inputMore:", inputMore);
    //         if (radioValue === "introduceRadio") {
    //             setExplainMoreValue(inputMore);
    //         } else if (radioValue === "translateRadio") {
    //             setExplainMoreValue(inputMore);
    //         } else if (radioValue === "otherRadio") {
    //             setExplainMoreValue(inputMore);
    //         }
    //     }
    // };

    const cleanChatLogAction = () => {
        if (!getLoginState()) {
            showWarningSnackbar(warnings.getFreeCreditsWarning);
            return;
        }
        const cleanedMessages = getLastNMessages(messages, -1, [0]);
        setMessages(cleanedMessages);
        setLocalMessages(cleanedMessages);
    };

    return (
        <>
            <div
                className="main-container container"
                style={{
                    flex: "1",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    width: "100vw",
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    justifyContent: "space-around",
                    alignItems: "center",
                }}
            >
                {/* --- GlasHive AI Assistant title --- */}
                <div
                    className="vertical-center-parent"
                    style={{
                        // flex: "8",
                        flexGrow: "0",
                        width: "100%",

                        fontSize: "1.5em",
                        color: "red",
                        padding: "10px",
                        marginTop: "15px",
                        marginBottom: "20px",
                        height: "10%",
                        textAlign: "center",
                    }}
                >
                    <div style={{ width: "100%", color: "#9de3d6", fontWeight: "bold" }} className="vertical-center-child">
                        <span>Trip AI Assistant Chat Box</span>
                        <br />
                        <button onClick={cleanChatLogAction} className="btn btn-link">
                            Clean Chat Logs
                        </button>
                    </div>
                </div>
                {/* --- GlasHive AI Assistant chat box --- */}
                <div
                    className=""
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        // flex: "92",
                        flexGrow: "1",
                        width: "100%",

                        maxHeight: "90%",
                        textAlign: "center",
                        fontSize: "1.2em",
                        position: "relative",
                    }}
                >
                    <MainContainer
                        responsive
                        id="chat-container"
                        style={{
                            flex: "1",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "5px",

                            overflow: "auto",

                            overflowScrolling: "touch",
                            WebkitOverflowScrolling: "touch",

                            position: "absolute",
                            width: "100%",
                        }}
                    >
                        <ChatContainer
                            style={{
                                // minHeight: "300px",
                                flex: "1",
                                overflow: "auto",
                                overflowY: "auto",
                                overflowScrolling: "touch",
                                WebkitOverflowScrolling: "touch",
                            }}
                        >
                            <MessageList
                                style={{
                                    flex: "1",
                                    // minHeight: "500px",
                                    overflow: "auto",
                                    overflowY: "auto",
                                    overflowScrolling: "touch",
                                    WebkitOverflowScrolling: "touch",

                                    // maxHeight: "61vh",
                                    // position: "static",
                                }}
                                id="gh-message-list"
                                className="message-list message-list-container"
                                scrollBehavior="smooth"
                                autoScrollToBottomOnMount={true}
                                autoScrollToBottom={true}
                                loadingMorePosition="bottom"
                                typingIndicator={isTyping ? <TypingIndicator content="GlasHive AI assistant is typing" /> : null}
                            >
                                {messages.map((m, i) =>
                                    m.type === "separator" ? (
                                        <MessageSeparator key={i} {...m.props} />
                                    ) : jsonKeyExists(m.props, "model.sourcetype") && m.props.model.sourcetype === "image" ? (
                                        // Message.HtmlContent
                                        <Message.HtmlContent
                                            key={i}
                                            // {...m.props}
                                            html={`
                                                    <div className="image-container" style="width: 100%; text-align: right; margin-top: 3px">
                                                    <img src=${m.props.model.file_url} style="width: 100px; height: 100px" />
                                                    </div>
                                                    `}
                                        />
                                    ) : (
                                        <Message key={i} {...m.props} style={{ textAlign: "justify", fontSize: "1.2em" }} />
                                    )
                                )}
                            </MessageList>

                            <MessageInput
                                value={inputValue}
                                onChange={handleInputChange}
                                placeholder="Please type message here..."
                                onSend={handleSend}
                                onAttachClick={handleAttach}
                                sendDisabled={sendButtonDisabled}
                                // onPaste={handlePaste}
                            />
                        </ChatContainer>
                    </MainContainer>
                </div>
            </div>

            {/*--- Footer Space ---Start*/}
            <Container
                id="footer-space"
                fluid
                style={{
                    padding: "0px",
                    minHeight: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            ></Container>
            {/*--- Footer Space ---End*/}

            {/*--- Modal Section ---Start*/}
            <div id="modal-container" className="modalContainer text-center">
                <Modal
                    size="lg"
                    show={showModal}
                    onHide={handleModalClose}
                    close={handleModalClose}
                    // backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="d-flex justify-content-center w-100">
                            <p style={{ fontSize: "0.8em", marginBottom: "0px" }}>
                                Drag & Drop image uploading &nbsp;
                                <span style={{ color: "red", fontSize: "0.6em" }}>*(Max {maxFilesUpload} Images)</span>
                            </p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container className="text-center">
                            <Container className="card">
                                <div className="top"></div>
                                <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                                    {isDragging ? (
                                        <span className="select">Drop images here</span>
                                    ) : (
                                        <>
                                            Drag & Drop image here or{" "}
                                            <span className="select" role="button" onClick={selectFiles}>
                                                Browse
                                            </span>
                                        </>
                                    )}

                                    <input
                                        name="file"
                                        type="file"
                                        className="file"
                                        multiple
                                        accept="image/*"
                                        ref={fileInputRef}
                                        onChange={onFilesSelect}
                                    ></input>
                                </div>

                                <div className="container" style={{ paddingTop: "12px" }}>
                                    {images.map((image, index) => (
                                        <div className="image" key={index}>
                                            <span className="delete" onClick={() => deleteImage(index)}>
                                                &times;
                                            </span>
                                            <img src={image.url} alt={image.name} />
                                        </div>
                                    ))}
                                </div>
                                <Container
                                    className="container ghoptions"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        minHeight: "330px",
                                    }}
                                >
                                    <Container
                                        style={{
                                            flexGrow: "1",
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Row style={{ width: "100%" }}>
                                            <Col xs={12} sm={4}>
                                                <Form.Check // prettier-ignore
                                                    value="introduce"
                                                    checked={radioValue === "introduce"}
                                                    type="radio"
                                                    id="introduce"
                                                    label="What is it?"
                                                    onChange={radioChange}
                                                />
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <Form.Check
                                                    value="translate"
                                                    checked={radioValue === "translate"}
                                                    type="radio"
                                                    label="Translate to"
                                                    id="translate"
                                                    onChange={radioChange}
                                                />
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <Form.Check
                                                    value="other"
                                                    checked={radioValue === "other"}
                                                    type="radio"
                                                    label="Other"
                                                    id="other"
                                                    onChange={radioChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                    <div className="form-floating" style={{ flexGrow: "2", width: "100%" }}>
                                        <div className="form-floating mb-3" style={{ display: hideExplainMore ? "none" : "block" }}>
                                            <input
                                                type="text"
                                                className="form-control d-grid"
                                                id="floatingInput"
                                                placeholder="Explain more (Max 100 chars)"
                                                value={explainMoreValue}
                                                // onChange={explainMoreInputChange}
                                                onChange={(event) => setExplainMoreValue(event.target.value)}
                                                maxLength="100"
                                            ></input>
                                            <label for="floatingInput">Explain more (Max 100 chars)</label>
                                        </div>

                                        <div className="form-floating mb-3 lang-select-div">
                                            {/* https://www.npmjs.com/package/react-flags-select */}
                                            <ReactFlagsSelect
                                                selected={selectedCountryCode}
                                                onSelect={(code) => setSelectedCountryCode(code)}
                                                countries={getCountryCodes()}
                                                customLabels={getCountryLanguageCustomLabels()}
                                                placeholder="Select a Country/Language"
                                                searchable
                                            />
                                        </div>
                                    </div>
                                </Container>
                            </Container>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container className="text-center">
                            <Row>
                                <Col xs={6} className="text-center d-grid">
                                    <Button variant="secondary" onClick={handleModalClose}>
                                        Close
                                    </Button>
                                </Col>
                                <Col xs={6} className="text-center d-grid">
                                    <Button
                                        variant={upSendButtonClass}
                                        disabled={upSendButtonDisabled}
                                        type="button"
                                        onClick={uploadImages}
                                    >
                                        Upload & Send
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            </div>
            {/*--- Modal Section ---End*/}

            {/*--- Snackbar Section ---Start*/}
            <div>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={snackbarAutoHideDuration} // Snackbar will auto close after 5 seconds
                    onClose={snackbarOnClose}
                    // anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={snackbarOnClose} severity={alertSeverity} variant={alertVariant} sx={{ width: "100%" }}>
                        {snackbarAlertText}
                    </Alert>
                </Snackbar>
            </div>
            {/*--- Snackbar Section ---End*/}
        </>
    );
}

export default AIAssistant;
