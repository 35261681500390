import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Row, Col } from "reactstrap";
import { getRequest, isEmptyNullUndefined, jsonKeyExists } from "../../lib";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkerAlt, faGlobe, faCameraRotate, faLightbulb } from "@fortawesome/free-solid-svg-icons";

import moherImage from "../../images/moher.png";
import frenchMenuImage from "../../images/french-menu.jpg";

import news1 from "../../images/app-overview/1.png";
import news2 from "../../images/app-overview/2.png";
import news3 from "../../images/app-overview/3.png";
import news4 from "../../images/app-overview/4.png";
import news5 from "../../images/app-overview/5.png";

// https://www.youtube.com/watch?v=OtqxDT0IlHI.
// https://github.com/emetdas/Youtube-code/blob/master/web%20components/React-Swiper-Slider-3D-Coverflow/final/src/App.jsx
// https://www.youtube.com/watch?v=6O0Eb3drgpc
// https://github.com/devamitjha/swiper-slider/blob/main/src/App.js

import "./index.css";

//---------------------------------------
const intervalSecond = 6000;

function AppCarousel(props) {
    const [index, setIndex] = useState(0);

    useEffect(() => {}, []);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const getCarousel = (
        <Row
            className="text-center justify-content-center"
            name="wigets"
            style={{ maxHeight: "600px", marginLeft: "0px", marginRight: "0px", padding: "0px" }}
        >
            <Col
                lg={10}
                md={12}
                xs={12}
                className="text-center align-self-center"
                style={{ maxHeight: "600px", marginLeft: "0px", marginRight: "0px", padding: "0px" }}
            >
                {/* Smart phone carousel news */}

                <Carousel controls={false} activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item interval={intervalSecond} key={1} style={{ minHeight: "575px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "575px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Chat Translation</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news1} style={{ width: "200px", maxHeight: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={2} style={{ minHeight: "575px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "575px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Dictionary</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news2} style={{ width: "200px", maxHeight: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={3} style={{ minHeight: "575px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "575px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Menu Translation</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news3} style={{ width: "200px", maxHeight: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={4} style={{ minHeight: "575px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "575px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Image Recognition</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news4} style={{ width: "200px", maxHeight: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval={intervalSecond} key={4} style={{ minHeight: "575px", width: "100%" }}>
                        <div className="container text-center" style={{ minHeight: "575px", width: "100%" }}>
                            <div className="row">
                                <p style={{ fontSize: "2em", fontWeight: "bold", color: "#0ED7B3", padding: "10px" }}>Trip Plan</p>
                                <div
                                    className="col-xs-12 col-sm-12 col-md-12"
                                    style={{ fontSize: "1.2em", fontWeight: "bold", color: "#0ED7B3" }}
                                >
                                    <img src={news5} style={{ width: "200px", maxHeight: "100%" }} />
                                </div>
                            </div>
                        </div>
                        <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    );

    return <div>{getCarousel}</div>;
}

export default AppCarousel;
