/**
 * This is the main file of this APP
 */
import { createBrowserHistory } from "history";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Footer, Navigation } from "./components";
// Pages
import { AboutUs, AccountDeletion, AIAssistant, EmailConfirm, ForgetPassword, Home, Profile, Signin, Signup } from "./pages";

const history = createBrowserHistory();
function App() {
    return (
        <Router history={history}>
            <Navigation />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/ai-assistant" exact element={<AIAssistant />} />
                <Route path="/signin" exact element={<Signin />} />
                <Route path="/signup" exact element={<Signup />} />
                <Route path="/profile" exact element={<Profile />} />
                <Route path="/aboutus" exact element={<AboutUs />} />
                <Route path="/emailconfirm" exact element={<EmailConfirm />} />
                <Route path="/forgetpassword" exact element={<ForgetPassword />} />
                <Route path="/account-deletion" exact element={<AccountDeletion />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
